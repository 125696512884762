import './App.css';
import Footer from './Components/Footer';
import Header from './Components/Header';
import Landing from './Components/Landing';
import { Route , Routes } from 'react-router-dom';
import Services from './Components/Services';
import About from './Components/About';
import Start from './Components/Start';
import Blog from './Components/Blog';
import Contact from './Components/Contact';
import Error from './Components/Error';

function App() {
  return (
    <>
    <Header />

    <div className="mt-40"> 
    <Routes>
    <Route path="/" element={<Start />} />
    <Route path="/service" element={<Services />} />
    <Route path="/about" element={<About />} />
    <Route path="/blogs" element={<Blog />} />
    <Route path="/contact-us" element={<Contact />} />
    <Route path="*" element={<Error />} />


  </Routes>
  <Landing />
</div>
  

    <Footer />
    
    </>
  );
}

export default App;
