import React from "react";
import { Bars } from 'react-loader-spinner';

export default function Loader() {
    return (
           <div id="loader">
             <Bars
                height="80"
                width="80"
                color="orange"
                ariaLabel="loading"
                wrapperStyle={{}}
                wrapperClass=""
            />
           </div>
    );
}
