import React  from "react";
import img from '../assets/img/insta.png'
import img2 from '../assets/img/facebook.png'
import img3 from '../assets/img/email.png'
import img4 from '../assets/img/phone.png'
import img5 from '../assets/img/whatsapp.png'
import img6 from '../assets/img/linkedin.png'

import logo from "../assets/img/logo.png"
import { NavLink } from "react-router-dom";

export default function Footer(){
    return(
        <>
        <footer className="text-gray-600 body-font">
  <div className="container px-5 py-8 mx-auto flex items-center sm:flex-row flex-col">
    <a className="flex title-font font-medium items-center md:justify-start justify-center text-gray-900">
      
    <img src={logo} alt="logo" height={100} width={100} />

    </a>
    <p className="text-sm text-gray-500 sm:ml-4 sm:pl-4 sm:border-l-2 sm:border-gray-200 sm:py-2 sm:mt-0 mt-4">© 2022 Coderills —
      <NavLink to='/' className="text-gray-600 ml-1" rel="noopener noreferrer" target="_blank">Software Company</NavLink>
    </p>
    <span className="inline-flex sm:ml-auto sm:mt-0 mt-4 justify-center sm:justify-start">
    <div className="contact-info flex">
            <a href="https://wa.me/+13022735104" className="contact-item whatsapp" target="_blank" rel="noopener noreferrer">
                <img src={img5} alt="WhatsApp" height={25} width={25}/> 
               
            </a> &nbsp; &nbsp;
            <a href="https://www.facebook.com/p/CODE-RILLS-100072239160183/" className="contact-item facebook" target="_blank" rel="noopener noreferrer">
                <img src={img2} alt="Facebook" height={25} width={25}/> 
             
            </a> &nbsp; &nbsp;
            <a href="mailto:youremail@example.com" className="contact-item email">
                <img src={img3} alt="Email" height={25} width={25}/> 
                
            </a> &nbsp; &nbsp;
            <a href="https://www.instagram.com/coderills/" className="contact-item instagram" target="_blank" rel="noopener noreferrer">
                <img src={img} alt="Instagram" height={25} width={25}/> 
               
            </a> &nbsp; &nbsp;
            <a href="https://pk.linkedin.com/company/coderills" className="contact-item linkedin" target="_blank" rel="noopener noreferrer">
                <img src={img6} alt="LinkedIn" height={25} width={25}/> 
            </a> &nbsp; &nbsp;
            <a href="tel:923104111946" className="contact-item phone">
                <img src={img4} alt="Phone" height={25} width={25}/> 
               
            </a> &nbsp; &nbsp;
        </div>
    </span>
  </div>
</footer>
        
        </>
    )
}