import React from "react";
import error from "../assets/img/404.png"
export default function Error(){
    return(
        <div>
            <center>            <img id='error'src={error} alt="error" width={400} height={400} />
            </center>
           
           
        </div>
    )
}