import React  from "react";
import main from "../assets/img/main.png"
import { NavLink } from "react-router-dom";

export default function Main(){
    return(
        <>
<section class="text-gray-600 body-font">
  <div class="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
  <div class="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
  <h1 class="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-100">Empowering Innovation with Coderills
  </h1>
  <p class="mb-8 leading-relaxed text-gray-100">At Coderills, we specialize in creating innovative, efficient, and scalable software tailored to your needs. Transform your challenges into exceptional software solutions. Let's build the future together.
  </p>
  <div class="flex justify-center">
    <NavLink to='/blogs'>    <button class="inline-flex text-white bg-yellow-500 border-0 py-2 px-6 focus:outline-none hover:bg-yellow-600 rounded text-lg">Blogs</button>
    </NavLink>
    <NavLink  to='/contact-us'>    <button class="ml-4 inline-flex text-gray-700 bg-gray-100 border-0 py-2 px-6 focus:outline-none hover:bg-gray-200 rounded text-lg">Contact Us</button>
    </NavLink>
  </div>
</div>

    <div class="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
    <img class="object-cover object-center rounded" alt="hero" src={main} />
    </div>
  </div>
</section>
        </>
    )
}