import React from "react";
import logo from "../assets/img/logo.png"
import { NavLink } from "react-router-dom";

export default function Header() {
    return (
        <>
     <header id="nav" className="text-gray-600 body-font fixed w-full top-0 ">
  <div className="container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center">
    <a href="/" className="flex title-font font-medium items-center text-gray-900 mb-4 md:mb-0">
      <img src={logo} alt="logo" height={80} width={80} />
    </a>
    <nav className="md:ml-auto flex flex-wrap items-center text-base justify-center">
      <NavLink id="sm" to='/' className="mr-5 text-gray-100" activeClassName="text-gray-900">HOME</NavLink>
      <NavLink id="sm" to='/service' className="mr-5 text-gray-100" activeClassName="text-gray-900">SERVICES</NavLink>
      <NavLink id="sm" to='/blogs' className="mr-5 text-gray-100" activeClassName="text-gray-900">BLOGS</NavLink>

      <NavLink id="sm" to='/about' className="mr-5 text-gray-100" activeClassName="text-gray-900">ABOUT</NavLink>
      <NavLink  to='/contact-us'>      <button className="inline-flex text-white bg-yellow-500 border-0 py-1 px-3 focus:outline-none hover:bg-yellow-600 rounded text-sm">CONTACT US</button>
      </NavLink>
      </nav>
  </div>
</header>

            </>
    );

}