import React, { useEffect, useState } from 'react';
import Main from './Main';
import Loader from './Loader';

export default function Start() {
    const [component, setComponent] = useState(false);

    useEffect(() => {
        setComponent(true);
        const timer = setTimeout(() => {
            setComponent(false);
        }, 1000);

        return () => clearTimeout(timer); 
    }, []);

    useEffect(()=>{
        window.scrollTo(0, 0);
    })

    return (
        <>
          <center>
          {component ? <Loader /> : <Main />}
          </center>
        </>
    );
}
