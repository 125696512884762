import React, { useEffect } from "react";
import img1 from "../assets/img/2.jpg";
import img2 from "../assets/img/3.jpg";
import img3 from "../assets/img/4.jpg";
import img4 from "../assets/img/5.jpg";
import img5 from "../assets/img/6.jpg";
import img6 from "../assets/img/7.jpg";
import img7 from "../assets/img/8.jpg";
import img8 from "../assets/img/9.jpg";

export default function Blog() {
    useEffect(()=>{
        window.scrollTo(0, 0);
    })
  return (
    <div style={{ backgroundColor: "black", color: "white" }}>
      <section className="body-font">
        <div className="container px-5 py-24 mx-auto">
          <div className="flex flex-wrap w-full mb-20">
            <div className="lg:w-1/2 w-full mb-6 lg:mb-0">
              <h1 className="sm:text-3xl text-2xl font-medium title-font mb-2">Blogs</h1>
              <div className="h-1 w-20 bg-yellow-500 rounded"></div>
            </div>
          </div>
          <div className="flex flex-wrap -m-4">
            {[ img1, img2, img3, img4, img5, img6, img7, img8].map((image, index) => (
              <div key={index} className="xl:w-1/4 md:w-1/2 p-4">
                <div className="bg-gray-800 p-6 rounded-lg">
                  <img className="h-40 rounded w-full object-cover object-center mb-6" src={image} alt="content" />
                  <h3 className="tracking-widest text-indigo-500 text-xs font-medium title-font">Services</h3>
                  <h2 className="text-lg font-medium title-font mb-4">Coderills {index + 1}</h2>
                  <p className="leading-relaxed text-base">Transform your challenges into exceptional software solutions. Let's build the future together.</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
}
