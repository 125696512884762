import React, { useEffect } from "react";

import serviceData from './service.json';

export default function Services() {
  useEffect(()=>{
    window.scrollTo(0, 0);
})
  return (
    <div className="container mx-auto px-5 py-24">
      <div style={{color: "white" }}>
      <div className="flex flex-wrap w-full mb-20">
            <div className="lg:w-1/2 w-full mb-6 lg:mb-0">
              <h1 className="sm:text-3xl text-2xl  font-medium title-font mb-2">Services</h1>
              <div className="h-1 w-20 bg-yellow-500 rounded"></div>            </div>
          </div>
          </div>
      <div className="flex flex-wrap -m-4">
      
        {serviceData.services.map((item, index) => (
          <div key={index} className="p-4 lg:w-1/3">
            <div id='cards' className="h-full bg-gray-900 bg-opacity-75 px-8 pt-16 pb-24 rounded-lg overflow-hidden text-center relative">
              <h2 className="tracking-widest text-xs title-font font-medium text-gray-100 mb-1">{item.category}</h2>
              <h1 className="title-font sm:text-2xl text-xl font-medium text-gray-100 mb-3">{item.name}</h1>
              <p className="leading-relaxed mb-3 text-gray-100 ">{item.description}</p>
              <a className="text-indigo-500 inline-flex items-center">Learn More
                <svg className="w-4 h-4 ml-2" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round">
                  <path d="M5 12h14"></path>
                  <path d="M12 5l7 7-7 7"></path>
                </svg>
              </a>
            
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
