import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Contact = () => {
    useEffect((e)=>{
        window.scrollTo(0, 0);
    })
    const notifySuccess = () => {
        toast.success("Message sent successfully", {
         
        });
      };
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        // console.log(name, message, email);
        // Add your submit logic here

        const data = {name:name,email:email,message:message}
        axios.post('https://sheet.best/api/sheets/e4151089-e332-43b4-b404-239676eeee2a', data).then(response =>{
            console.log(response);
            setName('');
            setEmail('');
            setMessage('');
            // alert('Message sent successfully')
            notifySuccess();
        })

    };

    return (
        <>
        <section className="text-gray-600 body-font">
            <div className="container px-5 py-24 mx-auto flex flex-wrap items-center">
                <div className="lg:w-3/5 md:w-1/2 md:pr-16 lg:pr-0 pr-0">
                    <h1 className="title-font font-medium text-3xl text-gray-100">Ready to transform your digital presence?</h1>
                    <p className="leading-relaxed mt-4 text-gray-100">Contact Coderills now for expert web development, SEO, and innovative AI solutions.</p>
                </div>
                <div className="lg:w-2/6 md:w-1/2 bg-gray-800 rounded-lg p-8 flex flex-col md:ml-auto w-full mt-10 md:mt-0">
                    <h2 className="text-gray-100 text-lg font-medium title-font mb-5">Contact Us</h2>
                    <div className="relative mb-4">
                        <label htmlFor="full-name" className="leading-7 text-sm text-gray-100">Full Name</label>
                        <input onChange={(e) => setName(e.target.value)} value={name} type="text" id="full-name" name="full-name" className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                    </div>
                    <div className="relative mb-4">
                        <label htmlFor="email" className="leading-7 text-sm text-gray-100">Email</label>
                        <input onChange={(e) => setEmail(e.target.value)} value={email} type="email" id="email" name="email" className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                    </div>
                    <div className="relative mb-4">
                        <label htmlFor="message" className="leading-7 text-sm text-gray-100">Message</label>
                        <input onChange={(e) => setMessage(e.target.value)} value={message} type="text" id="message" name="message" className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                    </div>
                    <button onClick={handleSubmit} className="text-white bg-yellow-500 border-0 py-2 px-8 focus:outline-none hover:bg-indigo-600 rounded text-lg">Go</button>
                </div>
            </div>
        </section>
           <ToastContainer />
           </>
    );
};

export default Contact;
