import React, { useEffect } from "react"
import img from "../assets/img/1.jpg";

export default function About(){
  useEffect(()=>{
    window.scrollTo(0, 0);
})
    return(
        <>
        <div className="bg-gray-1000 py-12">
      <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <h2 className="text-3xl font-extrabold text-gray-100 sm:text-4xl">
            About Us
          </h2>
          <p className="mt-4 text-lg text-gray-100">
            Welcome to Coderills, your one-stop destination for comprehensive digital solutions. Established in August 2022, we have quickly grown into a dynamic team of skilled professionals dedicated to bringing your vision to life through innovative technology and creative expertise.
          </p>
          <center>
          <img id='imgg' src={img} alt="img" height={400} width={400} />
          </center>
        </div>

      
      </div>
    </div>
        </>
    )
}